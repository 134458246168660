<template>
<div class="card" :class="{ 'border-warning': warningCard, 'border-danger': dangerCard }">
  <div class="card-body text-start p-0 rounded-2">
    <div class="card-header position-relative" :class="{ 'bg-light-warning': warningCard, 'bg-light-danger': dangerCard }">
      <h5 class="card-title text-primary-emphasis d-inline-block text-truncate m-0" :title="device.brand + ' ' + device.model + ' ' + device.version">{{ device.brand }} <span class="text-primary">{{ device.model }}</span> <span class="text-secondary">{{ device.version }}</span></h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{ device.devicetype }}</h6>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item p-0">
        <table class="border-secondary-subtle">
          <tbody>
            <tr>
              <th v-if="device.flashmb > 0" class="ps-2">{{ device.emmc ? "eMMC" : "Flash" }}</th>
              <th v-else class="ps-2">Storage</th>
              <td class="pe-2" v-if="device.flashmb > 0">{{ device.flashmb.toString() + "&thinsp;MB" }}</td>
              <td class="pe-2" v-else-if="device.memcard">{{ device.memtype }}</td>
              <td class="pe-2" v-else>Unknown</td>
              <th class="ps-2 th-border" :rowspan="wifiSupport.length < 10 ? 1 : 2">WiFi</th>
              <td class="pe-2" :rowspan="wifiSupport.length < 10 ? 1 : 2">{{ wifiSupport }}</td>
            </tr>
            <tr>
              <th class="ps-2">RAM</th>
              <td class="pe-2" v-if="device.rammb > 0">{{ device.rammb.toString() + "&thinsp;MB" }}</td>
              <td class="pe-2" v-else>Unknown</td>
              <template v-if="wifiSupport.length < 10">
                <th class="ps-2 th-border" rowspan="2">Ethernet</th>
                <td class="pe-2" rowspan="2">{{ ethernetSupport }}</td>
              </template>
            </tr>
            <tr>
              <th class="ps-2">Release</th>
              <td class="pe-2">{{ device.supportedcurrentrel }}</td>
              <template v-if="wifiSupport.length >= 10">
                <th class="ps-2 th-border" rowspan="1">Ethernet</th>
                <td class="pe-2" rowspan="1">{{ ethernetSupport }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </li>
      <li class="list-group-item position-absolute bottom-0 p-0 rounded-2">
        <a @click.prevent="routerOpenDevicePage()" :href="'device/' + device.pid" class="btn btn-outline-primary m-1 p-1 ps-2 pe-2">Details</a>
        <button class="btn btn-outline-success m-1 p-1 ps-2 pe-2" disabled>Availability</button>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "TohDeviceCard",
  props: ["device"],
  computed: {
    warningCard() {
      return this.device["snapshot_only"] || this.device["old_stable"] || this.device["unknown_firmware"];
    },
    dangerCard() {
      return !this.device["current_stable"] && !this.device["old_stable"] && !this.device["snapshot_only"];
    },
    ethernetSupport() {
      return this.getEthernetDescString(this.device);
    },
    wifiSupport() {
      if (this.device.wifi && this.device.wifi50) {
        return "802.11" + this.device.wlan50ghz.split("/").pop()
      } else if (this.device.wifi && this.device.wifi24) {
        return "802.11" + this.device.wlan24ghz.split("/").pop() + " (2.4\u{00A0}GHz)"
      } else {
        return "\u{2014}"
      }
    }
  },
  methods: {
    getEthernetDescString(device) {
      let output = [];
      if (device["ethernet100mports"] > 0) {
        output.push(device["ethernet100mports"].toString() + "x100M");
      }
      if (device["ethernet1gports"] > 0) {
        output.push(device["ethernet1gports"].toString() + "x1G");
      }
      if (device["ethernet2_5gports"] > 0) {
        output.push(device["ethernet2_5gports"].toString() + "x2.5G");
      }
      if (device["ethernet5gports"] > 0) {
        output.push(device["ethernet5gports"].toString() + "x5G");
      }
      if (device["ethernet10gports"] > 0) {
        output.push(device["ethernet10gports"].toString() + "x10G");
      }
      if (device["sfp_ports"] > 0) {
        output.push(device["sfp_ports"].toString() + "xSFP");
      }
      if (device["sfp_plus_ports"] > 0) {
        output.push(device["sfp_plus_ports"].toString() + "xSFP+");
      }
      return output.length === 0 ? "\u{2014}" : output.join(", ");
    },
    routerOpenDevicePage() {
      this.$router.push({ name: 'device', params: { pid: this.device.pid }});
    }
  }
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap.scss";
@media screen and (min-width: 46rem) {
  .card {
    width: 22rem;
    height: 12.25rem;
  }
}
@media screen and (max-width: 46rem) {
  .card {
    width: 100%;
    height: 12.25rem;
  }
}
.card-title {
  max-width: 100%;
}
table {
  width: 100%;
}
th {
  width: 20%;
}
td {
  text-align: right;
  width: 30%;
}
th.th-border {
  border-left-width: 1px;
  border-left-color: $card-border-color;
}
.bg-light-warning {
  background-color: $yellow-100;
}
.bg-light-danger {
  background-color: $red-100;
}
</style>