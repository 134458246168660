<template>
  <MainMenu />
  <RouterView />
  <FooterBar />
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import FooterBar from "@/components/FooterBar.vue";
export default {
  name: 'Openwrt-Compare',
  components: {
    MainMenu,
    FooterBar
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 40px;
}
</style>
