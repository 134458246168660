<template>
  <div class="container-xl position-relative top-0 justify-content-xl-center">
    <TohSidebar :all-device-types="allDeviceTypes" :result-count="filteredDevices.length" :items-per-page="itemsPerPage" @filter-changed="refreshFilter" @sort-changed="refreshSort"/>
    <TohCards :devices="shownDevices" />
    <TohPagination v-if="pageCount > 1" :page-count="pageCount" :current-page="currentPage" @previous="currentPage--" @next="currentPage++" @new-page="(n) => currentPage = n" />
  </div>
</template>

<script>
import TohCards from "@/components/TohCards.vue";
import TohSidebar from "@/components/TohFilterBar.vue";
import TohPagination from "@/components/TohPagination.vue";
export default {
  name: "TohView",
  components: {TohPagination, TohCards, TohSidebar},
  data() {
    return {
      "allDevices": [],
      "filteredDevices": [],
      "shownDevices": [],
      "allDeviceTypes": [],
      "itemsPerPage": 60,
      "currentPage": 1,
      "sort": "brand"
    }
  },
  mounted: async function() {
    // Reset the title
    document.title = "The Open Router Comparator";

    let response = await fetch(window.location.origin + "/api/devices")
    this.allDevices = await response.json()
    response = await fetch(window.location.origin + "/api/device_types")
    this.allDeviceTypes = await response.json()

    // Restore filter from sessionStorage or create new default filter
    if (sessionStorage.getItem("savedFilter")) {
      this.refreshFilter(JSON.parse(sessionStorage.getItem("savedFilter")))
    } else {
      this.refreshFilter({"snapshot": false, "obsolete": false, "discontinued": false, "currentStable": true, "oldStable": false, "selectedDeviceTypes": this.allDeviceTypes, "search": ""});
    }
  },
  methods: {
    refreshFilter(newFilter) {
      this.filteredDevices = this.allDevices.filter(
          (d) => (newFilter.selectedDeviceTypes.includes(d["devicetype"])) &&
              ((d["snapshot_only"] && newFilter.snapshot) ||
                  (d["current_stable"] && newFilter.currentStable) ||
                  (d["old_stable"] && newFilter.oldStable) ||
                  (!d["snapshot_only"] && !d["current_stable"] && !d["old_stable"] && newFilter.obsolete)
              )
              && ((newFilter["rammbmin"] === 0) || d["rammb"] >= newFilter["rammbmin"])
              && ((newFilter["flashmbmin"] === 0) || d["flashmb"] >= newFilter["flashmbmin"])
              && ((d["brand"] + " " + d["model"]).toLowerCase().includes(newFilter.search.toLowerCase()))
              && ((newFilter["ethmin"] === 0) || d["ethernettotal"] >= newFilter["ethmin"])
              && ((newFilter["wifimin"] === "") || (d["wlan24ghz"] + d["wlan50ghz"]).includes(newFilter["wifimin"]))
      );
      this.refreshSort(this.sort);
    },
    refreshSort(newSort) {
      this.sort = newSort;
      let sortLambdas = {
        'brand': (a,b) => a["brand"].localeCompare(b["brand"]),
        'ram (low to high)': (a,b) => a["rammb"] - b["rammb"],
        'ram (high to low)': (a,b) => b["rammb"] - a["rammb"],
        'flash (low to high)': (a,b) => a["flashmb"] - b["flashmb"],
        'flash (high to low)': (a,b) => b["flashmb"] - a["flashmb"],
        'ethernet ports (low to high)': (a,b) => a["ethernettotal"] - b["ethernettotal"],
        'ethernet ports (high to low)': (a,b) => b["ethernettotal"] - a["ethernettotal"]
      };
      this.filteredDevices.sort(sortLambdas[newSort]);
      this.refreshPages(this.filteredDevices);
    },
    refreshPages(filteredArray) {
      this.currentPage = 1
      this.itemsPerPage = Math.max(60, Math.ceil(this.filteredDevices.length / 10))
      this.shownDevices = filteredArray.slice(this.itemsPerPage * (this.currentPage - 1), this.itemsPerPage * this.currentPage)
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.filteredDevices.length / this.itemsPerPage)
    }
  },
  watch: {
    currentPage(newPage) {
      // Reset page number if it has an invalid new value
      if (newPage < 1 || newPage > this.pageCount) {
        this.currentPage = 1
        return
      }
      this.shownDevices = this.filteredDevices.slice(this.itemsPerPage * (newPage - 1), this.itemsPerPage * newPage)
    },
    filteredDevices(newFiltered) {
      this.currentPage = 1;
      this.refreshPages(newFiltered);
    }
  },
}
</script>

<style scoped>
</style>