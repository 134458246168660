<template>
  <div class="container">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-2 border-top">
      <p class="text-body-secondary" xmlns:cc="http://creativecommons.org/ns#" >The dataset used by this website is licensed under <a href="https://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display:inline-block;">CC BY-SA 4.0<img style="height:22px!important;margin-left:3px;vertical-align:text-bottom;" src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt=""><img style="height:22px!important;margin-left:3px;vertical-align:text-bottom;" src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt=""><img style="height:22px!important;margin-left:3px;vertical-align:text-bottom;" src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1" alt=""></a> and is partly based on information from the <a href="https://openwrt.org/">OpenWrt wiki</a>. The openrouters.net project is not affiliated with OpenWrt; <span style="font-weight: bolder;">OpenWrt</span> is a registered trademark owned by Software Freedom Conservancy.</p>
      <p class="text-body-secondary">The content of this page has been compiled automatically from publicly available data. While we do our best to offer the most accurate and up-to-date information, the openrouters.net project cannot guarantee the accuracy of the material contained on this website. Any use of this data is therefore strictly at your own risk.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterBar"
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap.scss";
footer a {
  color: $secondary-text-emphasis;
  text-decoration: none;
}
footer a:hover {
  color: black;
}
footer p {
  width: 100%;
}
</style>