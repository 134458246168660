<template>
  <div class="navbar navbar-expand-sm navbar-light p-0" aria-label="Filters">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#filterbarcollapse" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="filterbarcollapse">
        <ul class="navbar-nav flex-wrap me-auto mb-2 mb-sm-0">
          <!-- Release versions -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Supported release</a>
            <ul class="dropdown-menu">
              <li class="dropdown-item p-0 ps-2 position-relative">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="switch-snapshot" v-model="filter.snapshot">
                  <label class="form-check-label m-0" for="switch-snapshot">Snapshot</label>
                </div>
              </li>
              <li class="dropdown-item p-0 ps-2 position-relative">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="switch-current-stable" v-model="filter.currentStable">
                  <label class="form-check-label m-0" for="switch-current-stable">Current stable</label>
                </div>
              </li>
              <li class="dropdown-item p-0 ps-2 position-relative">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="switch-old-stable" v-model="filter.oldStable">
                  <label class="form-check-label m-0" for="switch-old-stable">Old stable</label>
                </div>
              </li>
              <li class="dropdown-item p-0 ps-2 position-relative">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="switch-obsolete" v-model="filter.obsolete">
                  <label class="form-check-label m-0" for="switch-obsolete">Obsolete</label>
                </div>
              </li>
            </ul>
          </li>
          <!-- Device types -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Device types</a>
            <ul class="dropdown-menu">
              <li class="dropdown-item p-0 ps-2 position-relative">
                <button class="btn btn-outline-primary m-1 p-0 ps-2 pe-2" style="width: 90%;" @click="toggleAll">{{ selectAllButtonText }}</button>
              </li>
              <template v-for="(t, index) in allDeviceTypes" :key="index">
              <li class="dropdown-item p-0 ps-2 position-relative">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :id="`check-${t}`" :value="t" v-model="filter.selectedDeviceTypes">
                  <label class="form-check-label m-0" :for="`check-${t}`" style="text-transform: capitalize">{{ t }}</label>
                </div>
              </li>
              </template>
            </ul>
          </li>
          <!-- Hardware spec -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Hardware</a>
            <ul class="dropdown-menu">
              <li class="dropdown-item-text p-0 px-2"><label for="input-rammb-min">Minimum RAM (MiB)</label></li>
              <li class="dropdown-item p-0 px-2 position-relative">
                <input type="number" class="form-control p-1" id="input-rammb-min" v-model.trim="filter.rammbmin">
              </li>
              <li><hr class="dropdown-divider"></li>
              <li class="dropdown-item-text p-0 px-2"><label for="input-flashmb-min">Minimum Flash (MiB)</label></li>
              <li class="dropdown-item p-0 px-2 position-relative">
                <input type="number" class="form-control p-1" id="input-flashmb-min" v-model.trim="filter.flashmbmin">
              </li>
            </ul>
          </li>
          <!-- Networking -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Networking</a>
            <ul class="dropdown-menu">
              <li class="dropdown-item-text p-0 px-2"><label for="input-eth-min">Ethernet ports</label></li>
              <li class="dropdown-item p-0 px-2 position-relative">
                <input type="number" class="form-control p-1" id="input-eth-min" v-model.trim="filter.ethmin">
              </li>
              <li><hr class="dropdown-divider"></li>
              <li class="dropdown-item-text p-0 px-2"><label for="input-wifi-min">Minimum Wi-Fi support</label></li>
              <li class="dropdown-item p-0 px-2 position-relative">
                <select class="form-select p-1" id="input-wifi-min" v-model.lazy.trim="filter.wifimin">
                  <option selected value=""></option>
                  <option v-for="(x, i) in ['ax','ac','n','g']" :key="x" :value="x">{{ '802.11' + x + ' (Wi-Fi ' + (6 - i).toString() + ')' }}</option>
                </select>
              </li>
            </ul>
          </li>
        </ul>
        <form role="search">
          <input class="form-control" type="search" placeholder="Search" aria-label="Search" v-model.trim="filter.search" @submit.prevent @keydown.enter.prevent="$event.target.blur()">
        </form>
      </div>
    </div>
  </div>
  <div class="p-0" aria-label="Currently selected filters">
    <button @click="resetFilters" class="filter-button-clear rounded-4 m-1 p-0 px-2">clear filters</button>
    <template v-if="!(filter.snapshot && filter.currentStable && filter.oldStable && filter.obsolete)">
      <button v-if="filter.snapshot" @click="this.filter.snapshot = false;" class="filter-mark-warning rounded-4 m-1 p-0 ps-2 pe-2">snapshot release</button>
      <button v-if="filter.currentStable" @click="this.filter.currentStable = false;" class="filter-mark-info rounded-4 m-1 p-0 ps-2 pe-2">current stable release</button>
      <button v-if="filter.oldStable" @click="this.filter.oldStable = false;" class="filter-mark-warning rounded-4 m-1 p-0 ps-2 pe-2">old stable release</button>
      <button v-if="filter.obsolete" @click="this.filter.obsolete = false;" class="filter-mark-danger rounded-4 m-1 p-0 ps-2 pe-2">obsolete releases</button>
    </template>
    <template v-if="filter.selectedDeviceTypes.length < allDeviceTypes.length">
      <button v-for="(t, index) in filter.selectedDeviceTypes" :key="index" @click="this.filter.selectedDeviceTypes = this.filter.selectedDeviceTypes.length > 1 ? this.filter.selectedDeviceTypes.filter((x) => x !== t) : this.allDeviceTypes;" class="filter-mark-info rounded-4 m-1 p-0 ps-2 pe-2">type {{t.toLowerCase()}}</button>
    </template>
    <button v-if="filter.rammbmin > 0" @click="this.filter.rammbmin = 0;" class="filter-mark-info rounded-4 m-1 p-0 px-2">ram >= {{ filter.rammbmin }} MB</button>
    <button v-if="filter.flashmbmin > 0" @click="this.filter.flashmbmin = 0;" class="filter-mark-info rounded-4 m-1 p-0 px-2">flash >= {{ filter.flashmbmin }} MB</button>
    <button v-if="filter.ethmin > 0" @click="this.filter.ethmin = 0;" class="filter-mark-info rounded-4 m-1 p-0 px-2">ethports >= {{ filter.ethmin }}</button>
    <button v-if="filter.wifimin" @click="this.filter.wifimin = '';" class="filter-mark-info rounded-4 m-1 p-0 px-2">wifi >= 802.11{{ filter.wifimin }}</button>
  </div>
  <div class="d-flex flex-wrap p-0 m-0 justify-content-between">
    <div class="position-relative start-0 ms-1 p-1 ps-3 text-secondary-emphasis dropdown">
      Sort items by <a class="text-primary-emphasis link-underline-primary dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{{ sort }}</a>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="#" @click.prevent="sort = 'brand'">Brand</a></li>
        <li><a class="dropdown-item" href="#" @click.prevent="sort = 'ram (low to high)'">RAM: low to high</a></li>
        <li><a class="dropdown-item" href="#" @click.prevent="sort = 'ram (high to low)'">RAM: high to low</a></li>
        <li><a class="dropdown-item" href="#" @click.prevent="sort = 'flash (low to high)'">Flash: low to high</a></li>
        <li><a class="dropdown-item" href="#" @click.prevent="sort = 'flash (high to low)'">Flash: high to low</a></li>
        <li><a class="dropdown-item" href="#" @click.prevent="sort = 'ethernet ports (low to high)'">Ethernet ports: low to high</a></li>
        <li><a class="dropdown-item" href="#" @click.prevent="sort = 'ethernet ports (high to low)'">Ethernet ports: high to low</a></li>
      </ul>
    </div>
    <div v-if="resultCount" class="position-relative end-0 p-1 pe-3 text-secondary-emphasis text-end" aria-label="Number of results">
      {{ resultCount }} results <span v-if="resultCount > itemsPerPage" id="itemsperpage" class="text-secondary">({{itemsPerPage}} items per page)</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TohSidebar",
  emits: ['filterChanged', 'sortChanged'],
  props: ['allDeviceTypes', 'resultCount', 'itemsPerPage'],
  data() {
    return {
      filter: {
        snapshot: false,
        currentStable: true,
        oldStable: false,
        obsolete: false,
        discontinued: false,
        selectedDeviceTypes: this.allDeviceTypes,
        search: "",
        rammbmin: 0,
        flashmbmin: 0,
        ethmin: 0,
        wifimin: "",
      },
      sort: "brand",
      selectAllButtonText: "Deselect all",
      restoredFilter: false,
    }
  },
  watch: {
    filter: {
      handler(newFilter) {
        this.$emit('filterChanged', newFilter);
        sessionStorage.setItem("savedFilter", JSON.stringify(this.filter))

        if (this.filter.selectedDeviceTypes.length < this.allDeviceTypes.length) {
          this.selectAllButtonText = "Select all"
        } else {
          this.selectAllButtonText = "Deselect all"
        }
      },
      deep: true
    },
    sort(newSort) {
      this.$emit('sortChanged', newSort)
    },
    allDeviceTypes(newList) {
      if (!this.restoredFilter) {
        this.filter.selectedDeviceTypes = newList;
      }
    },
  },
  methods: {
    toggleAll() {
      if (this.filter.selectedDeviceTypes.length === this.allDeviceTypes.length) {
        this.filter.selectedDeviceTypes = []
      } else {
        this.filter.selectedDeviceTypes = this.allDeviceTypes
      }
    },
    resetFilters() {
      this.filter = {
        snapshot: true,
            currentStable: true,
            oldStable: true,
            obsolete: true,
            discontinued: false,
            selectedDeviceTypes: this.allDeviceTypes,
            search: "",
            rammbmin: 0,
            flashmbmin: 0,
            ethmin: 0,
            wifimin: ''
      }
      this.showClearButton = false;
    }
  },
  mounted() {
    if (sessionStorage.getItem("savedFilter")) {
      this.restoredFilter = true;
      this.filter = JSON.parse(sessionStorage.getItem("savedFilter"));
    }
  }
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap.scss";
.filter-bar {
  width: 12rem;
  left: 1rem;
  z-index: 1020;
}
.filter-bar button {
  margin: 4px;
}
.filter-bar label {
  width: 100%;
}
h5 {
  font-size: 1rem;
  font-weight: bold;
  margin-left: 0.5rem;
}
.form-check-input {
  margin-right: 4px;
}
.filter-button-clear {
  color: $blue-700;
  border: 1px solid $blue-300;
  background-color: $blue-100;
  transition: background-color 0.25s, border 0.25s;
}
.filter-button-clear span {
  color: $gray-800;
}
.filter-button-clear:hover {
  border: 1px solid $blue-400;
  background-color: $blue-200;
}
.filter-mark-info {
  color: $gray-700;
  border: 1px solid $gray-300;
  background-color: $gray-100;
  transition: background-color 0.25s, border 0.25s;
}
.filter-mark-info span {
  color: $gray-800;
}
.filter-mark-warning {
  color: $gray-700;
  border: 1px solid $yellow-300;
  background-color: $yellow-100;
  transition: background-color 0.25s, border 0.25s;
}
.filter-mark-warning span {
  color: $gray-800;
}
.filter-mark-danger {
  color: $gray-700;
  border: 1px solid $red-200;
  background-color: $red-100;
  transition: background-color 0.25s, border 0.25s;
}
.filter-mark-info:hover, .filter-mark-warning:hover, .filter-mark-danger:hover {
  border: 1px solid $red-300;
  background-color: $red-200;
}
.filter-mark-danger span {
  color: $gray-800;
}
label {
  height: 100%;
  width: 100%;
}
ul.dropdown-menu {
  min-width: 14rem;
}
li input[type="number"] {
  width: 100%;
}
</style>