<template>
<nav class="m-0 mt-2" aria-label="Page navigation">
  <ul class="pagination flex-wrap m-0 justify-content-center">
    <li :class="{'disabled': currentPage === 1}" class="page-item"><a class="page-link" href="#" @click.prevent="$emit('previous')">Previous</a></li>
    <li class="page-item" v-for="n in pageCount" :key="n"><a class="page-link" :class="{'active': currentPage === n}" href="#" @click.prevent="$emit('newPage', n)">{{ n }}</a></li>
    <li :class="{'disabled': currentPage === pageCount}" class="page-item"><a class="page-link" href="#" @click.prevent="$emit('next')">Next</a></li>
  </ul>
</nav>
</template>

<script>
export default {
  name: "TohPagination",
  props: {
    "pageCount": Number,
    "currentPage": Number
  },
  emits: ['previous','next','newPage']
}
</script>

<style scoped>

</style>