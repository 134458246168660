<template>
  <div id="toh" class="row row-cols-1 row-cols-md-auto justify-content-center position-relative">
    <div class="col g-1 text-center text-primary-emphasis" v-show="devices.length === 0">
      No matching devices found.
      <br>
      You may want to remove some filters and try again.
    </div>
    <div class="col g-1" v-for="device in devices" :key="device.pid">
        <TohDeviceCard :device="device" />
    </div>
  </div>
</template>

<script>
import TohDeviceCard from "@/components/TohDeviceCard.vue";
export default {
  name: "TohCards",
  components: {
    TohDeviceCard
  },
  props: ["devices"]
}
</script>

<style scoped>
#toh {
  padding-left: 1rem; /* 14rem? */
  padding-right: 1rem;
}
</style>