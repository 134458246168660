<template>
  <div class="card border-warning bg-light-warning p-2 mb-1" :class="{'border-warning': !this.severe, 'border-danger': this.severe, 'bg-light-warning': !this.severe, 'bg-light-danger': this.severe}">
    <p class="m-0 p-0"><strong>Warning</strong>&nbsp;<slot/> <a v-if="link" :href="link" target="_blank">Learn more</a></p>
  </div>
</template>

<script>
export default {
  name: "DeviceWarning",
  props: {
    severe: Boolean,
    link: String
  }
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap.scss";
.card.bg-light-warning {
  background-color: $yellow-100;
}
.card.bg-light-danger {
  background-color: $red-100;
}
</style>