<template>
  <div class="container-sm rounded-2 position-relative top-0">
    <DeviceWarning v-if="device.snapshot_only" link="https://openwrt.org/releases/snapshot">
     This device is only supported by the snapshot build of OpenWrt. The most recent build does not include a graphical web interface, is untested and may be unstable.
    </DeviceWarning>
    <DeviceWarning v-if="device.old_stable" link="https://openwrt.org/releases/start">
      This device is currently only supported by the old stable build of OpenWrt ({{ device.supportedcurrentrel }}). This release is considered outdated, but will still receive limited security updates and bugfixes for some time.
    </DeviceWarning>
    <DeviceWarning severe v-if="!this.device.current_stable && !this.device.old_stable && !this.device.snapshot_only" link="https://openwrt.org/releases/start">
      This device is currently only supported by an obsolete build of OpenWrt ({{ device.supportedcurrentrel }}). This release is outdated and will not receive security updates and bugfixes in the future.
    </DeviceWarning>
    <DeviceWarning v-if="this.device.unknown_recovery" link="https://openwrt.org/docs/guide-user/troubleshooting/vendor_specific_rescue">
      There is no recovery method listed for this device. This means that it could be difficult or impossible to recover after a failed firmware upgrade.
    </DeviceWarning>
    <DeviceWarning v-if="this.device.unsupported_functions" :link="this.device.devicepage ? 'https://openwrt.org/' + this.device.devicepage : ''">
      The entry for this device indicates that some features are not yet supported by OpenWrt: <span class="citation px-1 py-0 rounded-1">{{device.unsupported_functions}}</span>.
    </DeviceWarning>
    <DevicePageSection header :device="device">
      <DevicePageEntry name="Device type" :value="device.devicetype" />
      <DevicePageEntry name="Latest supported OpenWrt release" :value="device.supportedcurrentrel" />
      <DevicePageEntry name="Target" :value="this.device.target + '/' + this.device.subtarget" />
    </DevicePageSection>
    <DevicePageSection name="Core specifications">
      <!-- Storage -->
      <DevicePageEntry v-if="device.flashmb > 0" :name="device.emmc ? 'eMMC' : 'Flash'" :value="device.flashmb.toString() + '&thinsp;MB'" />
      <DevicePageEntry v-else-if="device.memcard" name="Storage" :value="device.memtype" />
      <DevicePageEntry v-else name="Storage" value="Unknown" />
      <!-- RAM -->
      <DevicePageEntry v-if="device.rammb > 0" name="RAM" :value="device.rammb.toString() + '&thinsp;MB'" />
      <DevicePageEntry v-else name="RAM" value="Unknown" />
      <!-- Memory card support -->
      <DevicePageEntry name="Memory card support" :value="device.memcard ? 'Yes (' + device.memtype + ')' : 'No'" />
      <!-- CPU -->
      <DevicePageEntry name="CPU" :value="device.cpu + ' (' + device.cpucores + ' ' + (device.cpucores > 1 ? 'cores' : 'core') + ')'" />
      <DevicePageEntry name="CPU Frequency" :value="device.cpumhz + ' MHz'" />
    </DevicePageSection>
    <DevicePageSection name="Networking">
      <!-- Ethernet support -->
      <DevicePageEntry name="Ethernet ports" :value="ethernetSupport" />
      <!-- WiFi support -->
      <DevicePageEntry name="WiFi (2.4 GHz)" :value="device.wifi24 ? '802.11' + device.wlan24ghz.toLowerCase() : '\u{2014}'" />
      <DevicePageEntry name="WiFi (5 Ghz)" :value="device.wifi50 ? '802.11' + device.wlan50ghz.toLowerCase() : '\u{2014}'" />
    </DevicePageSection>
    <DevicePageSection name="References">
      <!-- OpenWrt device page and ToH page -->
      <DevicePageEntry v-if="device.devicepage" name="OpenWrt Device Page" :link="'https://openwrt.org/' + device.devicepage" :value="device.devicepage" />
      <DevicePageEntry v-if="device.page" name="OpenWrt Table of Hardware" :link="'https://openwrt.org/' + device.page" :value="device.page" />
      <!-- WikiDevi device page -->
      <DevicePageEntry v-if="device.wikideviurl" name="WikiDevi" :link="device.wikideviurl" :value="decodeURI(device.wikideviurl.split('/').pop())" />
    </DevicePageSection>
  </div>
</template>

<script>
import DeviceWarning from "@/components/DeviceWarning.vue";
import DevicePageSection from "@/components/DevicePageSection.vue";
import DevicePageEntry from "@/components/DevicePageEntry.vue";

export default {
  name: "DeviceView",
  components: {DevicePageEntry, DevicePageSection, DeviceWarning},
  props: ["pid"],
  data() {
    return {
      "device": {}
    }
  },
  methods: {
    getEthernetDescString(device) {
      let output = [];
      if (device["ethernet100mports"] > 0) {
        output.push(device["ethernet100mports"].toString() + "×100M");
      }
      if (device["ethernet1gports"] > 0) {
        output.push(device["ethernet1gports"].toString() + "×1G");
      }
      if (device["ethernet2_5gports"] > 0) {
        output.push(device["ethernet2_5gports"].toString() + "×2.5G");
      }
      if (device["ethernet5gports"] > 0) {
        output.push(device["ethernet5gports"].toString() + "×5G");
      }
      if (device["ethernet10gports"] > 0) {
        output.push(device["ethernet10gports"].toString() + "×10G");
      }
      if (device["sfp_ports"] > 0) {
        output.push(device["sfp_ports"].toString() + "×SFP");
      }
      if (device["sfp_plus_ports"] > 0) {
        output.push(device["sfp_plus_ports"].toString() + "×SFP+");
      }
      return output.join(", ")
    },
  },
  computed: {
    ethernetSupport() {
      return this.getEthernetDescString(this.device);
    },
  },
  mounted: async function() {
    let response = await fetch(window.location.origin + "/api/device/" + this.pid);
    this.device = await response.json();
    document.title = this.device.brand + " " + this.device.model + (this.device.version ? " " + this.device.version : "") + " – The Open Router Comparator";
    let desc = document.createElement('meta');
    desc.name = 'description';
    desc.content = 'Full hardware specifications for the ' + this.device.brand + " " + this.device.model + (this.device.version ? " " + this.device.version : "") + (this.device.devicetype !== 'other' ? " " + this.device.devicetype.toLowerCase() : "") + ". Compare with other devices supported by open source firmware like OpenWrt and see prices and availability online.";
    document.head.appendChild(desc);
  },
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap.scss";
.container-sm {
  max-width: 740px;
}
.citation {
  font-size: smaller;
  font-family: monospace;
  background-color: rgba(0,0,0,0.1);
}
</style>