<template>
  <div class="card my-1">
    <!-- Only used for the first section -->
    <h5 v-if="header" class="h5-header rounded-2 text-primary-emphasis p-2 m-0" :title="device.brand + ' ' + device.model">
      <button @click="routerPrevious()" class="btn btn-outline-secondary d-inline ps-2 pe-2 pt-0 pb-0 me-2" aria-label="Go back">← Go back</button>
      {{ device.brand }} <span class="text-primary">{{ device.model }}</span> {{ device.version }}</h5>
    <div v-if="name" class="card-header">{{ name }}</div>
    <table class="border-secondary-subtle">
      <tbody>
        <slot />
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DevicePageSection",
  props: {
    "device": Object,
    "name": String,
    "header": Boolean
  },
  methods: {
    routerPrevious() {
      this.$router.push({path: '/'});
    },
  }
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap.scss";
.h5-header {
  background-color: $card-cap-bg;
  width: 100%;
}
table {
  width: 100%;
}
</style>