<template>
  <h1 class="mt-4">The Open Router Comparator</h1>
  <p class="mb-3">The all-in-one tool to help you find your next OpenWrt device.</p>
  <div class="container-sm mx-auto pb-4">
    <div class="row align-items-center justify-content-center">
      <!--<div class="col-sm-auto">
        <button @click="this.$router.push({path: 'wizard'});" class="btn btn-outline-primary">Start with our wizard</button>
      </div>
      <div class="col-sm-auto">
        or
      </div>-->
      <div class="col-auto">
        <a v-if="this.$route.fullPath !== '/'" @click="routerRoot" class="btn btn-outline-secondary">Explore the available hardware</a>
        <a v-else href="#toh" class="btn btn-outline-secondary">Explore the available hardware</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  methods: {
    routerRoot() {
      this.$router.push({path: '/'});
    }
  }
}
</script>

<style scoped>
h1, p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>