import { createRouter, createWebHistory } from 'vue-router'

import TohView from "@/components/TohView.vue";
import DeviceView from "@/components/DeviceView.vue";
import WizardView from "@/components/WizardView.vue";

const routes = [
    { path: '/', component: TohView },
    { path: '/device/:pid', name: 'device', component: DeviceView, props: true },
    { path: '/wizard', name: 'wizard', component: WizardView}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router