<template>
  <div class="container-lg position-relative top-0 justify-content-l-center">
    <div v-if="step === 'START'" class="card">
      <div class="card-header">
        <h5 class="card-title m-0">
          First, what kind of device are you looking for?
        </h5>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item text-primary-emphasis"><a @click="this.step = 'ROUTER'" href="#" class="stretched-link">Router <br/><span class="text-body-secondary">Routers are used to connect multiple networks together, with two or more Ethernet ports.</span></a></li>
        <li class="list-group-item text-primary-emphasis"><a @click="this.step = 'WAP'" href="#" class="stretched-link">Wireless Access Point <br/><span class="text-body-secondary">APs connect wireless devices to a wired local network. They are usually less powerful than routers, and include a single Ethernet port.</span></a></li>
        <li class="list-group-item text-primary-emphasis"><a @click="this.step = 'SBC'" href="#" class="stretched-link">Single Board Computers <br/><span class="text-body-secondary">SBCs are versatile all-in-one boards with embedded CPU, memory and storage. (e.g. Raspberry Pi boards, PINE A64+, FriendlyARM NanoPi)</span></a></li>
        <li class="list-group-item text-primary-emphasis"><a @click="this.step = 'OTHER'" href="#" class="stretched-link">Something else <br/><span class="text-body-secondary">Modems, powerline adapters, range extenders, network switches, NAS, IP cameras...</span></a></li>
      </ul>
    </div>
    <div v-if="step === 'ROUTER'" class="card">
      <div class="card-header">
        <h5 class="card-title m-0">
          What type of router specifically?
        </h5>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item text-primary-emphasis"><a href="#" class="stretched-link">Router <br/><span class="text-body-secondary">Routers are used to connect multiple networks together, with two or more Ethernet ports.</span></a></li>
        <li class="list-group-item text-primary-emphasis"><a href="#" class="stretched-link">Wireless Access Point <br/><span class="text-body-secondary">APs connect wireless devices to a wired local network. They are usually less powerful than routers, and include a single Ethernet port.</span></a></li>
        <li class="list-group-item text-primary-emphasis"><a href="#" class="stretched-link">Single Board Computers <br/><span class="text-body-secondary">SBCs are versatile all-in-one boards with embedded CPU, memory and storage. (e.g. Raspberry Pi boards, PINE A64+, FriendlyARM NanoPi)</span></a></li>
        <li class="list-group-item text-primary-emphasis"><a href="#" class="stretched-link">Something else <br/><span class="text-body-secondary">Modems, powerline adapters, range extenders, network switches, NAS, IP cameras...</span></a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "WizardView",
  data() {
    return {
      step: "START",
      filter: {}
    }
  }
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap.scss";
li a {
  color: inherit;
  text-decoration: inherit;
}
li:hover {
  background-color: #fbfbff;
}
</style>