<template>
  <tr>
    <th class="ps-2">{{ name }}</th>
    <td v-if="link" class="pe-2"><a :href="link" target="_blank">{{ value }}</a></td>
    <td v-else class="pe-2">{{ value }}</td>
  </tr>
</template>

<script>
export default {
  name: "DevicePageEntry",
  props: {
    "name": String,
    "value": String,
    "link": String
  }
}
</script>

<style scoped>
th {
  width: 50%;
}
td {
  text-align: right;
  width: 50%;
}
tr:hover {
  background-color: #fcfcfc;
}
</style>